import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { Table } from "../../../s25-table/Table";
import { Bind } from "../../../../decorators/bind.decorator";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { UserService } from "../../../../services/user.service";
import { UserprefService } from "../../../../services/userpref.service";
import { OptBean } from "../../../s25-opt/s25.opt.component";
import { OptUtil } from "../../../s25-opt/s25.opt.util";
import { S25Datefilter } from "../../../s25-dateformat/s25.datefilter.service";
import { S25ItemContactComponent } from "../../../s25-item/item-object/s25.item.contact.component";
import { S25Util } from "../../../../util/s25-util";

@TypeManagerDecorator("s25-ng-signed-in-users")
@Component({
    selector: "s25-ng-signed-in-users",
    template: `
        <div *ngIf="init" class="signed-in-users-wrapper">
            <h2 class="c-margin-bottom--single">User Sessions</h2>
            <s25-ng-opt [modelBean]="optBean"></s25-ng-opt>
            <s25-ng-table
                [dataSource]="tableConfig"
                [hasFilter]="true"
                [columnSortable]="true"
                [pivotThreshold]="900"
            ></s25-ng-table>
        </div>
    `,
    styles: `
        .signed-in-users-wrapper {
            position: relative;
            max-width: 1200px;

            .s25-item-link {
                cursor: pointer;
            }

            .left {
                z-index: 1;
            }

            .ngOptBar {
                position: absolute;
                top: 20px;
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25SignedInUsersComponent implements OnInit {
    init: boolean;
    tableConfig: Table.DataSource;
    optBean: OptBean;

    columns: Table.Column[] = [
        { id: "name", header: "Name" },
        { id: "email", header: "Email Address" },
        { id: "date", header: "Date Signed In" },
        { id: "computer", header: "Computer Used" },
    ];

    @ViewChild(S25TableComponent) tableComp: S25TableComponent;

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        this.optBean = await OptUtil.formSignedInUsersListBean(this.refresh, null);
        this.optBean.hasHideViewerSeat = true;

        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: this.columns,
        };

        this.init = true;
        this.cd.detectChanges();
    }

    @Bind
    async getRows(): Promise<Table.DataSourceResponse> {
        const contactToFilter = this.optBean.hideViewerSeat ? "viewer25" : null;

        const [sessions, dateTimePref] = await Promise.all([
            UserService.getUserSessions(contactToFilter),
            UserprefService.getS25DateTimeformat(),
        ]);

        return {
            rows: sessions?.map((session) => {
                return {
                    id: session.session_id,
                    name: session.user_name,
                    cells: {
                        name: {
                            component: S25ItemContactComponent,
                            inputs: {
                                modelBean: {
                                    itemName: session.contact_name,
                                    itemId: +session.contact_id,
                                },
                            },
                            textValue: session.contact_name,
                        },
                        email: { text: session.email },
                        date: {
                            text: S25Datefilter.transform(session.logon_date, dateTimePref),
                            sortValue: S25Util.date.toS25ISODateTimeStr(session.logon_date),
                        },
                        computer: { text: session.computer },
                    },
                };
            }),
        };
    }

    @Bind
    refresh() {
        return this.tableComp.refresh();
    }
}
