import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25Util } from "../../../util/s25-util";
import { Integration, IntegrationService, IntegrationTypes } from "../../integrations/integration.service";

@TypeManagerDecorator("s25-ng-stripe-settings")
@Component({
    selector: "s25-ng-stripe-settings",
    template: `<div *ngIf="init" class="stripe-settings-wrapper">
        <h2>Stripe Settings</h2>
        <s25-toggle-button
            class="ngBlock"
            [(modelValue)]="stripeIntegration.enabled"
            [toggleLabel]="'Enable Stripe Payments'"
            [falseLabel]="'Disabled'"
            [trueLabel]="'Enabled'"
        ></s25-toggle-button>
        <label class="c-margin-top--single ngBlock"
            >Re-enter Key
            <s25-toggle-button
                *ngIf="stripeKeyExists"
                [(modelValue)]="reenterKey"
                (modelValueChange)="onReEnterKey()"
                [toggleLabel]="'Re-enter Key'"
                [falseLabel]="'No'"
                [trueLabel]="'Yes'"
            ></s25-toggle-button>
        </label>
        <label class="c-margin-top--single ngBlock" *ngIf="!stripeKeyExists || reenterKey">
            Stripe Key
            <input
                type="password"
                autocomplete="off"
                class="c-input c-margin-right--half c-margin-left--half"
                [(ngModel)]="stripeIntegration.apiKey"
            />
        </label>
        <button class="aw-button aw-button--primary ngBlock c-margin-top--single" (click)="save()">Save</button>
        <div class="save-msg c-margin-top--single" [class.saved]="showSaveMsg">
            <s25-ng-icon [type]="'check'" />
            Settings saved successfully
        </div>
    </div>`,
    styles: `
        .save-msg {
            opacity: 0;
            transition: opacity 0.5s ease-out;
            color: green;
        }

        .save-msg.saved {
            opacity: 1;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25StripeSettingsComponent implements OnInit {
    init: boolean;
    stripeIntegration: Integration;
    stripeKeyExists = false;
    reenterKey = false;
    showSaveMsg: boolean;

    constructor(private cd: ChangeDetectorRef) {}

    async ngOnInit() {
        this.stripeIntegration = await IntegrationService.getIntegration(IntegrationTypes.stripe);
        if (this.stripeIntegration) {
            this.stripeKeyExists = true;
            this.stripeIntegration.apiKey = "";
        } else {
            this.stripeIntegration = await IntegrationService.createIntegration({
                apiKey: "",
                enabled: false,
                integrationType: IntegrationTypes.stripe,
            });
        }
        this.init = true;
        this.cd.detectChanges();
    }

    save = S25Util.debounceTs(async () => {
        let promises: Promise<any>[] = [];
        promises.push(
            IntegrationService.setEnabled(
                this.stripeIntegration.integrationId,
                this.stripeIntegration.integrationType,
                this.stripeIntegration.enabled,
            ),
        );

        // only save stripe apiKey if it has a value and the key previously did not exist OR the
        // user has indicated they want to re-enter it
        // note: auto-complete pw add-ons can autofill this field even with autocomplete="off"
        if (this.stripeIntegration.apiKey && (!this.stripeKeyExists || this.reenterKey)) {
            promises.push(
                IntegrationService.setApiKey(this.stripeIntegration.integrationId, this.stripeIntegration.apiKey),
            );
        }
        return S25Util.all(promises)
            .then(() => {
                this.showSaveMsg = true;
                this.cd.detectChanges();

                setTimeout(() => {
                    this.showSaveMsg = false;
                    this.cd.detectChanges();
                }, 5000);
            })
            .catch((e) => {
                S25Util.showError(e);
            });
    });

    onReEnterKey = () => {
        this.cd.detectChanges();
    };
}
