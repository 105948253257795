import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    standalone: true,
    name: "datesInString",
})
export class DatesInStringPipe implements PipeTransform {
    transform(value: string, format: string = "S25|EEE yyyy MMM dd h:mm a"): string {
        // Regular expression to match the datetime format in the string
        const regex = /\d{8}T\d{6}(-\d{4})?/g;
        let matches;

        // Replace each datetime string found with its formatted version
        while ((matches = regex.exec(value)) !== null) {
            const dateStr = matches[0];
            const formattedDate = S25Datefilter.transform(dateStr, format);
            // Replace the original datetime string with its formatted version
            value = value.replace(dateStr, formattedDate);
        }

        return value;
    }
}
