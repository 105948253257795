//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PreferenceService } from "../../../services/preference.service";

@TypeManagerDecorator("task-settings")
@Component({
    selector: "task-settings",
    template: `
        <div *ngIf="!this.initLoading">
            <h2 class="c-margin-bottom--single">Task Search Settings</h2>
            <div class="ngBold">In pre-defined task searches, display tasks with due dates between</div>
            <div class="c-margin-top--quarter">
                <input
                    type="text"
                    id="startDate"
                    [(ngModel)]="this.startDate"
                    name="startDate"
                    class="c-input c-margin-right--half"
                    (ngModelChange)="this.onChange()"
                    required
                    type="number"
                />
                <label for="startDate">days in the past, and</label>
                <input
                    type="text"
                    id="endDate"
                    [(ngModel)]="this.endDate"
                    name="endDate"
                    class="c-input c-margin-right--half c-margin-left--half"
                    (ngModelChange)="this.onChange()"
                    required
                    type="number"
                />
                <label for="endDate">days in the future</label>
            </div>
            <div *ngIf="!this.startDate || !this.endDate" class="ngRed">Please enter a valid number.</div>

            <div class="c-displayBlock c-padding-bottom--double">
                <button
                    class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                    (click)="save()"
                    [disabled]="!this.startDate || !this.endDate"
                >
                    {{ this.loading ? "Saving...." : "Save" }}
                </button>
                <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()">Cancel</button>
            </div>

            <div
                *ngIf="this.message && this.message !== ''"
                class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single"
                role="alert"
            >
                <div class="cn-alert__icon cn-icon" name="alert--info">
                    <svg class="cn-svg-icon" role="img">
                        <title>Success Alert</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                        ></use>
                    </svg>
                </div>
                <div class="cn-alert__label">
                    <span>{{ this.message }}</span>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskSettingsComponent implements OnInit {
    startDate: number = 0;
    endDate: number = 0;
    loading: boolean = false;
    initLoading: boolean = true;

    message: string = "";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    onChange() {
        this.cd.detectChanges();
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.getPrefs();
        this.cd.detectChanges();
    }

    getPrefs() {
        return S25Util.all({
            prefs: PreferenceService.getPreferences(["TasksDueStartDtRange", "TasksDueEndDtRange"], "S"),
        }).then((resp) => {
            this.startDate = resp.prefs.TasksDueStartDtRange.value;
            this.endDate = resp.prefs.TasksDueEndDtRange.value;
            this.initLoading = false;
            this.cd.detectChanges();
        });
    }

    save() {
        this.loading = true;
        this.cd.detectChanges();

        return S25Util.all({
            setPrefEamil: PreferenceService.setPreference("TasksDueStartDtRange", this.startDate, "S"),
            setPrefShow: PreferenceService.setPreference("TasksDueEndDtRange", this.endDate, "S"),
        }).then((resp) => {
            this.loading = false;
            this.message = "Success!";
            this.cd.detectChanges();
        });
    }

    cancel() {
        this.getPrefs();
    }
}
